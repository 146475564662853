import React, { useEffect, useState } from "react"
import Empleados from "../Empleados/Empleados"
import Eventos from "../Eventos/Eventos"
import BusquedaEgresados from "../Egresados/BusquedaEgresados/BusquedaEgresados"
import { useLocation, useNavigate } from "react-router-dom"

export default function OpcionesAdmin() {
  const [selectedOption, setSelectedOption] = useState("eventos")
  const [sidebarVisible, setSidebarVisible] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname.split("/").pop()
    if (path && options.find((option) => option.value === path)) {
      setSelectedOption(path)
    } else {
      navigate("/admin/eventos")
    }
  }, [location, navigate])

  const handleOptionChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const optionValue = event.currentTarget.value
    setSelectedOption(optionValue)
    navigate(`/admin/${optionValue}`)
  }

  const handleSidebarToggle = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const options = [
    { name: "Eventos", value: "eventos" },
    { name: "Buscar Egresados", value: "egresados" },
    { name: "Empleados", value: "empleados" },
  ]

  return (
    <div className="d-flex flex-wrap" style={{ height: "70vh" }}>
      <div className="flex-shrink-0">
        {!sidebarVisible ? (
          <button
            className="btn btn-dark position-fixed"
            style={{
              borderRadius: "0px",
              height: "100%",
              width: "3rem",
            }}
            onClick={handleSidebarToggle}
          >
            <i className="bi bi-view-list text-info h4"></i>
          </button>
        ) : (
          <div
            className="bg-dark py-3 px-2 position-fixed"
            style={{
              width: "11rem",
              height: "100%",
              overflowY: "auto",
              marginTop: "4rem",
            }}
          >
            <button
              className="btn btn-outline-danger my-1 w-100 "
              onClick={handleSidebarToggle}
            >
              <i className="bi bi-x-square-fill"></i>
            </button>
            {options.map((option) => (
              <div
                className="row justify-content-start my-3"
                key={option.value}
              >
                <div className="col">
                  <button
                    value={option.value}
                    onClick={handleOptionChange}
                    className={`btn btn-outline-info w-100 text-center ${
                      selectedOption === option.value ? "active p-3" : ""
                    }`}
                  >
                    {option.name}
                  </button>
                </div>
              </div>
            ))}
            <div
              style={{
                marginTop: "5rem",
              }}
            >
              <br />
            </div>
          </div>
        )}
      </div>

      <div
        className={`flex-grow-1`}
        style={{
          transition: "all 0.3s ease",
          marginTop: "5rem",
          overflowY: "auto",
          marginLeft: sidebarVisible ? "11rem" : "3rem",
        }}
      >
        {selectedOption === "eventos" && <Eventos />}
        {selectedOption === "egresados" && <BusquedaEgresados />}
        {selectedOption === "empleados" && <Empleados />}
      </div>
    </div>
  )
}
