import React, { useEffect, useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { useAuth0 } from "@auth0/auth0-react"
import { IEventos } from "../../../interfaces/IEventos"
import { formatDateWithDay } from "../../../utils/dateUtils"
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge"
import SpinnerOverlay from "../../SpinnerOverlay/SpinnerOverlay"
import { Alert, Snackbar } from "@mui/material"

interface ConteoIngresosProps {
  show: boolean
  handleClose: () => void
  idEvento: number | null
}

const ConteoIngresos: React.FC<ConteoIngresosProps> = ({
  show,
  handleClose,
  idEvento,
}) => {
  const [evento, setEvento] = useState<IEventos | null>(null)
  const [tarjetasPagadas, setTarjetasPagadas] = useState(0)
  const [tarjetasIngresadas, setTarjetasIngresadas] = useState(0)
  const [tarjetasPorIngresar, setTarjetasPorIngresar] = useState(0)
  const [tarjetasPagadasMenores, setTarjetasPagadasMenores] = useState(0)
  const [tarjetasIngresadasMenores, setTarjetasIngresadasMenores] = useState(0)
  const [tarjetasPorIngresarMenores, setTarjetasPorIngresarMenores] =
    useState(0)
  const API_EVENTO_URL = `${process.env.REACT_APP_API_SERVER_URL_EVENTOS}/${idEvento}`
  const API_CONTEOS_URL = `${process.env.REACT_APP_API_SERVER_URL_EGRE}/conteos/${idEvento}`
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)

  const fetchEventoData = async () => {
    setLoading(true)
    const token = await getAccessTokenSilently()
    try {
      const response = await fetch(API_EVENTO_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.ok) {
        const data = await response.json()
        setEvento(data)
      } else {
        throw new Error("Evento no encontrado")
      }
    } catch (error) {
      console.error(error)
      setEvento(null)
    } finally {
      setLoading(false)
    }
  }

  const fetchConteos = async () => {
    setLoading(true)
    const token = await getAccessTokenSilently()
    try {
      const response = await fetch(API_CONTEOS_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.ok) {
        const data = await response.json()
        setTarjetasPagadas(data.totalTarjetasPagadas)
        setTarjetasIngresadas(data.totalTarjetasIngresadas)
        setTarjetasPorIngresar(data.totalTarjetasPorIngresar)
        setTarjetasPagadasMenores(data.totalTarjetasPagadasMenores)
        setTarjetasIngresadasMenores(data.totalTarjetasIngresadasMenores)
        setTarjetasPorIngresarMenores(data.totalTarjetasPorIngresarMenores)
      } else {
        throw new Error("Error al obtener conteos")
      }
    } catch (error) {
      console.error(error)
      setTarjetasPagadas(0)
      setTarjetasIngresadas(0)
      setTarjetasPorIngresar(0)
      setTarjetasPagadasMenores(0)
      setTarjetasIngresadasMenores(0)
      setTarjetasPorIngresarMenores(0)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (show && idEvento) {
      fetchEventoData()
      fetchConteos()
    }
  }, [show, idEvento])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {evento
            ? `${evento.nombre} / ${formatDateWithDay(evento.fecha)}`
            : !loading && (
                <Snackbar
                  open={!evento}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert severity="warning">
                    {"¡Debe seleccionar un evento!"}
                  </Alert>
                </Snackbar>
              )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <SpinnerOverlay />}
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ width: "450px", height: "180px" }}>
            <Gauge
              value={tarjetasIngresadas + tarjetasIngresadasMenores}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 34,
                  transform: "translate(0px, 0px)",
                },
              }}
              text={({ value, valueMax }) => `${value} / ${valueMax}`}
              valueMax={tarjetasPagadas + tarjetasPagadasMenores}
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              width: "225px",
              height: "140px",
              textAlign: "center",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          >
            <Gauge
              value={tarjetasPorIngresar + tarjetasPorIngresarMenores}
              startAngle={0}
              endAngle={360}
              innerRadius="80%"
              outerRadius="100%"
              valueMax={tarjetasPagadas + tarjetasPagadasMenores}
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 34,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#b20202", // Color rojo
                },
              })}
            />
            <h5 style={{ color: "#b20202" }}>Tarjetas por Ingresar</h5>
            <h6
              style={{ color: "#b20202", fontSize: "13px" }}
            >{`Mayores: ${tarjetasPorIngresar} - Menores: ${tarjetasPorIngresarMenores}`}</h6>
          </div>

          <div
            style={{
              width: "225px",
              height: "140px",
              textAlign: "center",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          >
            <Gauge
              value={tarjetasIngresadas + tarjetasIngresadasMenores}
              startAngle={0}
              endAngle={360}
              innerRadius="80%"
              outerRadius="100%"
              valueMax={tarjetasPagadas + tarjetasPagadasMenores}
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 34,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#52b202", // Color verde
                },
              })}
            />
            <h5 style={{ color: "#52b202" }}>Tarjetas Ingresadas</h5>
            <h6
              style={{ color: "#52b202", fontSize: "13px" }}
            >{`Mayores: ${tarjetasIngresadas} - Menores: ${tarjetasIngresadasMenores}`}</h6>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-5 d-flex justify-content-center">
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={fetchConteos}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConteoIngresos
