import React, { useEffect, useState } from "react"
import { QRCodeSVG } from "qrcode.react"
import { Alert, Button, Collapse, IconButton, Typography } from "@mui/material"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import "dayjs/locale/es"
import jsPDF from "jspdf"
import { toPng } from "html-to-image"
import "./GeneradorQRparaModal.css"
import Spinner from "../../../Spinner/Spinner"
import { useSpinner } from "../../../../contexts/SpinnerProvider"
import { encryptText } from "../../../../utils/claveAndEncryptUtils"
import { handleRequest } from "../../../FuncionRequest/FuncionRequest"

dayjs.locale("es") // Establecer la localización en español
dayjs.extend(localizedFormat) // Extender con localizedFormat
interface QRCodePrintProps {
  qrData: string
  nombre: string
  apellido: string
  tarjetasPagadas: string
  tarjetasPagadasMenores: string
  fechaEvento: string
}
const QRCodePrint: React.FC<QRCodePrintProps> = ({
  qrData,
  nombre,
  apellido,
  tarjetasPagadas,
  tarjetasPagadasMenores,
  fechaEvento,
}) => {
  // Formatear la fecha
  const formattedDate = dayjs(fechaEvento).format("dddd, D [de] MMMM [de] YYYY")
  const capitalizeFirstLetter = (text: string) => {
    if (!text) return ""
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  const [iconosCargados, setIconosCargados] = useState(false)
  const iconoDaVinci = "/assets/img/IconoDaVinci.ico"
  const iconoKids = "/assets/img/kids.ico"

  useEffect(() => {
    const loadIcons = async () => {
      const images = [iconoDaVinci, iconoKids].map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.src = src
          img.className = "qr-icon"
          img.onload = resolve
          img.onerror = reject
        })
      })
      try {
        await Promise.all(images)
        setIconosCargados(true)
      } catch (error) {
        console.error("Error al cargar los íconos:", error)
      }
    }
    loadIcons()
  }, [])
  if (!iconosCargados) {
    return <Spinner />
  }

  return (
    <div id="printable-area" className="qr-code-container">
      {" "}
      {!!tarjetasPagadas && (
        <div className="border border-2 p-2 rounded mb-3">
          {" "}
          <Typography
            variant="h6"
            component="h4"
            textAlign="center"
            className="mb-4"
          >
            {" "}
            <strong>{capitalizeFirstLetter(formattedDate)}</strong>{" "}
          </Typography>{" "}
          <div className="qr-code-wrapper">
            {" "}
            <QRCodeSVG
              value={qrData}
              bgColor={"#ffffff"}
              fgColor={"#aa2228"}
              imageSettings={{
                src: iconoDaVinci,
                height: 32,
                width: 36,
                excavate: true,
              }}
              size={190}
              level="M"
            />{" "}
          </div>{" "}
          <h5 className="mt-3 text-success">Información del Egresado:</h5>{" "}
          <div className="d-flex flex-column align-items-start ms-5">
            {" "}
            <p className="fs-6">
              {" "}
              <strong>Nombre:</strong> {nombre} {apellido}{" "}
            </p>{" "}
            <p className="fs-6">
              {" "}
              <strong>Cantidad de tarjetas disponibles:</strong>{" "}
              {tarjetasPagadas}{" "}
            </p>{" "}
          </div>{" "}
        </div>
      )}{" "}
      {!!tarjetasPagadasMenores && (
        <div className="border border-2 p-2 rounded mb-3">
          {" "}
          <Typography
            variant="h6"
            component="h4"
            textAlign="center"
            className="mb-4"
          >
            {" "}
            <strong>{capitalizeFirstLetter(formattedDate)}</strong>{" "}
          </Typography>{" "}
          <div className="qr-code-wrapper">
            {" "}
            <QRCodeSVG
              value={qrData + "-V"}
              bgColor={"#ffffff"}
              fgColor={"#2244aa"}
              imageSettings={{
                src: iconoKids,
                height: 32,
                width: 36,
                excavate: true,
              }}
              size={190}
              level="M"
            />{" "}
          </div>{" "}
          <h5 className="mt-3 text-success">Información del Egresado:</h5>{" "}
          <div className="d-flex flex-column align-items-start ms-5">
            {" "}
            <p className="fs-6">
              {" "}
              <strong>Nombre:</strong> {nombre} {apellido}{" "}
            </p>{" "}
            <p className="fs-6">
              {" "}
              <strong>Cantidad de tarjetas de Menores disponibles:</strong>{" "}
              {tarjetasPagadasMenores}{" "}
            </p>{" "}
          </div>{" "}
        </div>
      )}{" "}
    </div>
  )
}

interface GeneradorQRparaModalProps {
  dni?: number
  clave?: string
}

const GeneradorQRparaModal: React.FC<GeneradorQRparaModalProps> = ({
  dni: propDni,
  clave: propClave,
}) => {
  const dni = propDni || ""
  const clave = propClave || ""
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [tarjetasPagadas, setTarjetasPagadas] = useState("")
  const [tarjetasPagadasMenores, setTarjetasPagadasMenores] = useState("")
  const [fechaEvento, setFechaEvento] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [qrData, setQrData] = useState("")
  const API_URL = process.env.REACT_APP_API_SERVER_URL_VALID || ""
  const [mensajeAlert, setMensajeAlert] = useState<React.ReactNode>(undefined)
  const [tipoAlert, setTipoAlert] = useState<"error" | "warning">("error")
  const { addLoading, removeLoading } = useSpinner()

  const downloadImage = async (): Promise<string | null> => {
    const node = document.getElementById("printable-area")
    if (!node) {
      console.error('El elemento "printable-area" no se encontró.')
      return null
    }

    try {
      const dataUrl = await toPng(node, {
        quality: 1,
        width: node.offsetWidth * 2,
        height: node.offsetHeight * 2,
        backgroundColor: "#ffffff",
      })

      return dataUrl
    } catch (error) {
      console.error("Oops, algo salió mal al generar la imagen:", error)
      return null
    }
  }

  const handleMensajeAlert = (
    mensaje: React.ReactNode = undefined,
    tipo: "error" | "warning" = "error"
  ) => {
    setMensajeAlert(mensaje)
    setTipoAlert(tipo)
  }

  const handleGenerateQR = async () => {
    try {
      const dniClaveData = `${dni}-${encryptText(clave)}`
      const requestData = { dni, clave }
      const response = await handleRequest("POST", API_URL, requestData)

      if (response.message === "sin tarjetas") {
        handleMensajeAlert(
          <div>
            <span style={{ display: "inline-block" }}>
              No posee tarjetas para generar QR,{" "}
            </span>
            <span style={{ display: "inline-block" }}>
              comuníquese con administración.
            </span>
          </div>
        )
        setIsValid(false)
        return
      }

      if (response.message === "Plazo expirado") {
        handleMensajeAlert(
          "La fecha de generación de QR para este evento ha expirado."
        )
        setIsValid(false)
        return
      }

      if (response.message === "Validado correctamente") {
        handleMensajeAlert()
        setQrData(dniClaveData)
        setIsValid(true)
        setNombre(response.nombre)
        setApellido(response.apellido)
        setTarjetasPagadas(response.tarjetasPagadas)
        setTarjetasPagadasMenores(response.tarjetasPagadasMenores)
        setFechaEvento(response.fechaEvento)
      } else if (response.status === 400) {
        setIsValid(false)
        handleMensajeAlert("DNI o clave incorrectos.", "warning")
      }
    } catch (err) {
      console.error("Error de validación:", err)
      setIsValid(false)
      handleMensajeAlert(
        "Error de credenciales o al recuperar los datos en el servidor.",
        "error"
      )
    }
  }

  const handleDownloadPDF = async (
    apellido: string,
    nombre: string
  ): Promise<void> => {
    const contentElement = document.getElementById("printable-area")
    if (!contentElement) {
      console.error("No se encontró el elemento 'printable-area'.")
      return
    }

    try {
      addLoading()
      const dataUrl = await downloadImage()
      if (!dataUrl) {
        console.error("Error al obtener la imagen.")
        return
      }

      const pdf = new jsPDF("p", "pt", "a4")

      const imgWidth = pdf.internal.pageSize.width - 110
      const pageHeight = window.innerHeight
      const pageWidth = window.innerWidth

      const imgHeight =
        (contentElement.clientHeight * imgWidth) / contentElement.clientWidth

      const x = pageWidth / (pageWidth > 768 ? 8 : 2.5)
      const divisor = !!tarjetasPagadas && !!tarjetasPagadasMenores ? 18 : 20
      const y = pageHeight / divisor
      pdf.setFillColor(255, 255, 255) // Blanco
      pdf.rect(
        0,
        0,
        pdf.internal.pageSize.width,
        pdf.internal.pageSize.height,
        "F"
      )
      pdf.addImage(dataUrl, "PNG", x, y, imgWidth, imgHeight)

      pdf.save(`QR_${apellido}_${nombre}.pdf`)
    } catch (error) {
      console.error("Error al generar el PDF:", error)
    } finally {
      removeLoading()
    }
  }

  useEffect(() => {
    handleGenerateQR()
  }, [])

  return (
    <div>
      <div className="container text-center">
        <div
          style={{ maxWidth: "500px", margin: "auto", marginBottom: "50px" }}
        >
          {!!mensajeAlert && (
            <Collapse in={!!mensajeAlert}>
              <Alert
                severity={tipoAlert}
                variant="filled"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => handleMensajeAlert()}
                  ></IconButton>
                }
                sx={{
                  textAlign: "center",
                }}
              >
                {mensajeAlert}
              </Alert>
            </Collapse>
          )}

          {isValid && (
            <div className="qr-code text-center">
              <div id="printable-area">
                <QRCodePrint
                  qrData={qrData}
                  nombre={nombre}
                  apellido={apellido}
                  tarjetasPagadas={tarjetasPagadas}
                  tarjetasPagadasMenores={tarjetasPagadasMenores}
                  fechaEvento={fechaEvento}
                />
              </div>
              <div className="d-flex flex-row justify-content-center gap-5">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDownloadPDF(apellido, nombre)}
                >
                  Descargar PDF
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GeneradorQRparaModal
