import React, { useState, useRef, useEffect } from "react"
import { QRCodeSVG } from "qrcode.react"
import { scanQRCode } from "../Util/scanQRCode"
import QRMessage from "./QRMessage"
import { useAuth0 } from "@auth0/auth0-react"
import "./EmpleadoPage.css"
import Spinner from "../Spinner/Spinner"
import SelectorEventosModal from "./SelectorEventosModal/SelectorEventosModal"
import ConteoIngresos from "./ConteoIngresos/ConteoIngresos"
import axios from "axios"
import { sortEventosByDate } from "../../utils/dateUtils"
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material"
import { toast } from "react-toastify"

const EmpleadoPage = () => {
  const [dni, setDni] = useState<string>("")
  const [clave, setClave] = useState<string>("")
  const [esMenor, setEsMenor] = useState("")
  const [qrData, setQrData] = useState("")
  const [cameraStarted, setCameraStarted] = useState(false)
  const [showNoCameraAccessMessage, setShowNoCameraAccessMessage] =
    useState(false)
  const [camerasAvailable, setCamerasAvailable] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [showMessage, setShowMessage] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [tarjetasPorIngresar, setTarjetasPorIngresar] = useState("")
  const [observaciones, setObservaciones] = useState("")
  const [messageVariant, setMessageVariant] = useState("danger")
  const [mostrarMesa, setMostrarMesa] = useState(false)
  const [autoRestartCamera, setAutoRestartCamera] = useState(false)
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE_VERIF || ""
  const API_URL_MENOR =
    process.env.REACT_APP_API_SERVER_URL_EGRE_VERIF_MENORES || ""
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [showEventModal, setShowEventModal] = useState(false)
  const [showConteoModal, setShowConteoModal] = useState(false)
  const [eventId, setEventId] = useState<number | null>(null)
  const API_URL_EVENTOS = process.env.REACT_APP_API_SERVER_URL_EVENTOS || ""
  const [snackbarOpenError, setSnackbarOpenError] = useState(false)
  const [snackbarMessageError, setSnackbarMessageError] = useState<string>("")

  const fetchEventos = async () => {
    setLoading(true)
    try {
      const token = await getAccessTokenSilently()
      const response = await axios.get(API_URL_EVENTOS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const eventosOrdenados = sortEventosByDate(response.data)
      setEventId(eventosOrdenados[0].id)
    } catch (error) {
      console.error("Error al obtener eventos:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const cameraStateFromLocalStorage = localStorage.getItem("cameraStarted")
    if (cameraStateFromLocalStorage === "true") {
      setCameraStarted(true)
      setupCamera()
    }
    fetchEventos()
  }, [])

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras = devices.filter((device) => device.kind === "videoinput")
        setCamerasAvailable(cameras.length > 0)
      })
      .catch((error) => {
        console.error("Error al enumerar dispositivos:", error)
        setCamerasAvailable(false)
      })
  }, [])

  const toggleCamera = () => {
    if (cameraStarted) {
      if (videoRef.current && "srcObject" in videoRef.current) {
        const stream = videoRef.current.srcObject as MediaStream
        if (stream) {
          const tracks = stream.getTracks()
          tracks.forEach((track) => track.stop())
        }
      }
      setCameraStarted(false)
      localStorage.setItem("cameraStarted", "false")
    } else {
      setupCamera()
    }
  }

  const setupCamera = async () => {
    requestCameraPermission()
    setCameraStarted(true)
  }

  useEffect(() => {
    if (cameraStarted) {
      requestAnimationFrame(handleScan)
    }
  }, [cameraStarted])

  useEffect(() => {
    return () => {
      if (cameraStarted && videoRef.current) {
        if ("srcObject" in videoRef.current) {
          const stream = videoRef.current.srcObject as MediaStream
          const tracks = stream.getTracks()
          tracks.forEach((track) => track.stop())
        }
        setCameraStarted(false)
        localStorage.setItem("cameraStarted", "false")
      }
    }
  }, [])

  const closeModal = () => {
    toast.dismiss()
    if (cameraStarted) {
      toggleCamera()
    }
    setQrData("")
    setShowMessage(false)
    setTarjetasPorIngresar("")
    setObservaciones("")
    setMessageText("")
    setMostrarMesa(false)
  }

  useEffect(() => {
    if (autoRestartCamera) {
      setupCamera()
      setAutoRestartCamera(false)
    }
  }, [autoRestartCamera])

  const requestCameraPermission = async () => {
    try {
      const constraints = {
        video: {
          facingMode: "environment",
        },
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints)

      if (videoRef.current) {
        videoRef.current.srcObject = stream

        videoRef.current.addEventListener("loadedmetadata", () => {
          if (videoRef.current) {
            const width = videoRef.current.videoWidth
            const height = videoRef.current.videoHeight
            if (width > 0 && height > 0) {
              videoRef.current.play().then(() => {
                localStorage.setItem("cameraStarted", "true")
              })
            } else {
              console.error("Las dimensiones del video no son válidas.")
              setShowNoCameraAccessMessage(true)
            }
          }
        })
      }
    } catch (error) {
      console.error("Error al acceder a la cámara:", error)
      setShowNoCameraAccessMessage(true)
    }
  }

  const handleScan = async () => {
    if (!videoRef.current) {
      console.error("El elemento de video no está disponible.")
      return
    }

    const videoElement = videoRef.current

    await new Promise<void>((resolve) => {
      if (videoElement.videoWidth && videoElement.videoHeight) {
        resolve()
      } else {
        videoElement.onloadedmetadata = () => {
          resolve()
          videoElement.onloadedmetadata = null
        }
      }
    })

    scanQRCode(videoElement, (qrData: string) => {
      const scannedData = qrData.split("-")
      const dni = scannedData[0]
      const clave = scannedData[1]
      const esQRmenor = scannedData[2]
      setDni(dni)
      setClave(clave)
      setEsMenor(esQRmenor)
      verificarIngreso(dni, clave, esQRmenor, 0)
    })
  }

  // Función para manejar el descuento de tarjetas
  const handleDescontarTarjetas = async (cantidad: number) => {
    if (cantidad > 0 && dni && clave) {
      // Llamar a la función verificarIngreso, pasando el DNI y la clave almacenados
      await verificarIngreso(dni, clave, esMenor, cantidad)
    }
  }

  const verificarIngreso = async (
    dni: string,
    clave: string,
    esQRmenor: string,
    tarjetasADescontar: number
  ) => {
    setLoading(true)
    try {
      const token = await getAccessTokenSilently()
      let response = new Response()
      if (esQRmenor === "V") {
        response = await fetch(API_URL_MENOR, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ dni, clave, tarjetasADescontar }),
        })
      } else {
        response = await fetch(API_URL, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ dni, clave, tarjetasADescontar }),
        })
      }

      if (response.status === 200) {
        if (tarjetasADescontar > 0) {
          toast.success(
            tarjetasADescontar === 1
              ? `Se descontó ${tarjetasADescontar} tarjeta`
              : `Se descontaron ${tarjetasADescontar} tarjetas`,
            {
              position: "top-center",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          )
        }

        const responseData = await response.json()
        setMessageText(
          `${responseData.nombre} ${responseData.apellido}\nDNI: ${responseData.dni}\nTarjetas pagadas: ${responseData.tarjetasPagadas}\n`
        )
        setTarjetasPorIngresar(`${responseData.tarjetasPorIngresar}`)
        setObservaciones(`${responseData.observaciones}`)
        setMessageVariant("success")
        setMostrarMesa(true)
      } else if (response.status === 418) {
        const responseData = await response.json()
        setObservaciones(`${responseData.observaciones}`)
        setMessageText(
          `${responseData.message}\n${responseData.nombre} ${responseData.apellido}\nDNI: ${responseData.dni}\n`
        )
        setMessageVariant("danger")
        setMostrarMesa(true)
      } else {
        setMessageText("Ingreso Denegado.")
        setMessageVariant("danger")
      }
      setShowMessage(true)
    } catch (error) {
      // Si ocurre un error, mostrar notificación de error
      toast.error(
        "Hubo un problema al procesar la operación. Intenta nuevamente.",
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      )

      setSnackbarMessageError("Error al verificar o descontar tarjetas.")
      setSnackbarOpenError(true)
      console.error("Error al verificar el ingreso:", error)
    } finally {
      setLoading(false)
    }
  }
  const handleNewAction = () => {
    setShowEventModal(true) // Abre el modal al hacer clic en el botón
  }

  const handleCloseModal = () => {
    setShowEventModal(false) // Cierra el modal
  }

  const handleSelectEvent = (selectedEventId: number) => {
    setEventId(selectedEventId) // Guarda el eventId seleccionado
    handleCloseModal() // Cierra el modal
  }

  const handleShowConteoModal = () => {
    setShowConteoModal(true)
  }

  const handleCloseConteoModal = () => {
    setShowConteoModal(false)
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return
    }
    setSnackbarOpenError(false)
  }

  if (loading) return <Spinner />

  return (
    <>
      <Snackbar
        open={snackbarOpenError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000} // 3 segundos
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {snackbarMessageError}
        </Alert>
      </Snackbar>

      <div className="container mt-5">
        <div className="d-flex flex-column align-items-center">
          <h1
            className="display-4 text-center mb-4"
            style={{ marginTop: "40px" }}
          >
            Escanea el código QR
          </h1>
          {showNoCameraAccessMessage && (
            <div className="alert alert-danger">
              No se ha concedido acceso a la cámara. Por favor, otorgue acceso y
              recargue la página.
            </div>
          )}
          {!camerasAvailable && (
            <div className="alert alert-danger">
              No se detectaron cámaras disponibles. Asegúrese de que haya una
              cámara conectada y recargue la página.
            </div>
          )}
          {cameraStarted ? (
            <div className="embed-responsive embed-responsive-4by3">
              <video
                ref={videoRef}
                className="embed-responsive-item"
                width={"500px"}
              ></video>
            </div>
          ) : (
            <p className="text-info fs-4 text-center">
              Inicie la cámara para empezar
            </p>
          )}
          {qrData && (
            <div className="text-center mt-4">
              <QRCodeSVG value={qrData} size={256} />
            </div>
          )}
          {showMessage && (
            <QRMessage
              message={messageText}
              variant={messageVariant}
              mostrarMesa={mostrarMesa}
              tarjetasPorIngresar={tarjetasPorIngresar}
              observaciones={observaciones}
              onClose={closeModal}
              onDescontarTarjetas={handleDescontarTarjetas}
            />
          )}
        </div>
        {/* Footer para móvil */}
        <div className="footer d-none d-sm-block">
          <button
            className="btn btn-secondary footer-button me-3"
            onClick={handleNewAction}
          >
            Evento
          </button>
          <button
            className="btn btn-primary footer-button"
            onClick={toggleCamera}
            disabled={!camerasAvailable}
          >
            <i className="bi bi-qr-code-scan"></i>{" "}
          </button>
          <button
            className="btn btn-secondary footer-button ms-3"
            onClick={handleShowConteoModal}
          >
            Conteo
          </button>
        </div>
        <div className="footer-mobile d-block d-sm-none">
          <button
            className="btn btn-secondary footer-button me-3"
            onClick={handleNewAction}
          >
            Evento
          </button>
          <button
            className="btn btn-primary footer-button"
            onClick={toggleCamera}
            disabled={!camerasAvailable}
          >
            <i className="bi bi-qr-code-scan"></i>{" "}
          </button>
          <button
            className="btn btn-secondary footer-button ms-3"
            onClick={handleShowConteoModal}
          >
            Conteo
          </button>
        </div>
        {/* Modal para seleccionar eventos */}
        <SelectorEventosModal
          show={showEventModal}
          handleClose={handleCloseModal}
          handleSelectEvent={handleSelectEvent}
        />
        {/* Modal de Conteo de Ingresos */}
        <ConteoIngresos
          show={showConteoModal}
          handleClose={handleCloseConteoModal}
          idEvento={eventId}
        />
      </div>
    </>
  )
}

export default EmpleadoPage
