/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { IEgresados } from "../../interfaces/IEgresados"
import { Action, Column } from "../../interfaces/CamposTablaGenerica"
import { handleRequest } from "../FuncionRequest/FuncionRequest"
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap"
import GenericTable from "../GenericTable/GenericTable"
import AddEgresadoModal from "./AddEgresadoModal"
import EditEgresadoModal from "./EditEgresadoModal/EditEgresadoModal"
import { useAuth0 } from "@auth0/auth0-react"
import ImportExcelModal from "./ImportExcelModal"
import SkeletonGrid from "../Skeleton/SkeletonGrid"
import { Link, useParams } from "react-router-dom"
import ExcelJS from "exceljs"
import { formatDateGuiones } from "../../utils/dateUtils"
import { copyDataEgresado } from "../../utils/claveAndEncryptUtils"
import {
  Alert,
  Box,
  Checkbox,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material"
import { useSpinner } from "../../contexts/SpinnerProvider"

const Egresados = () => {
  const [egresados, setEgresados] = useState<IEgresados[]>([])
  const [editModalShow, setEditModalShow] = useState(false)
  const [addModalShow, setAddModalShow] = useState(false)
  const [importExcelShow, setImportExcelShow] = useState(false)
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE || ""
  const API_URL_EVENTOS = process.env.REACT_APP_API_SERVER_URL_EVENTOS || ""
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedToDelete, setSelectedToDelete] = useState<IEgresados | null>(
    null
  )
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false)
  const [selectedEditUsuario, setSelectedEditUsuario] =
    useState<IEgresados | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const { addLoading, removeLoading } = useSpinner()
  const [skeletonLoading, setSkeletonLoading] = useState(false)
  const { id } = useParams()
  const idEvento = id || ""
  const tieneEvento = idEvento !== ""
  const [tituloEvento, setTituloEvento] = useState("")
  const [tarjetasPagadas, setTarjetasPagadas] = useState(0)
  const [tarjetasPagadasMenores, setTarjetasPagadasMenores] = useState(0)
  const [showSecondDeleteConfirmation, setShowSecondDeleteConfirmation] =
    useState(false)
  const [copyMessage, setCopyMessage] = useState<string>("")
  const [snackbarOpenError, setSnackbarOpenError] = useState(false)
  const [snackbarMessageError, setSnackbarMessageError] = useState<string>("")

  const callApiWithToken = async (
    method: string,
    endpoint: string,
    body?: object
  ) => {
    const token = await getAccessTokenSilently()

    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          ...(method !== "GET" && { "Content-Type": "application/json" }),
        },
        ...(method !== "GET" && { body: JSON.stringify(body) }),
      })

      if (response.ok) {
        return await response.json()
      } else {
        throw new Error("Error al realizar la solicitud")
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const callApiAndSetEgresados = async () => {
    setSkeletonLoading(true)
    try {
      const responseData = await callApiWithToken(
        "GET",
        `${API_URL}?eventoId=${idEvento}`
      )
      // Ordenar responseData por apellido
      const sortedData = responseData.sort((a: IEgresados, b: IEgresados) => {
        const apellidoA = a.apellido.toLowerCase()
        const apellidoB = b.apellido.toLowerCase()
        if (apellidoA < apellidoB) return -1
        if (apellidoA > apellidoB) return 1
        return 0
      })

      setEgresados(sortedData)
    } catch (error) {
      console.error(error)
    } finally {
      setSkeletonLoading(false)
    }
  }

  const callApiAndSetEvento = async () => {
    if (idEvento) {
      addLoading()
      try {
        const responseData = await callApiWithToken(
          "GET",
          `${API_URL_EVENTOS}/${idEvento}`
        )
        setTituloEvento(
          `${responseData.nombre} / ${formatDateGuiones(responseData.fecha)}`
        )
        setTarjetasPagadas(parseInt(responseData.totalTarjetasPagadas))
        setTarjetasPagadasMenores(
          parseInt(responseData.totalTarjetasPagadasMenores)
        )
      } catch (error) {
        console.error("Error al obtener el evento:", error)
      } finally {
        removeLoading()
      }
    }
  }

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IEgresados
  ) => {
    const newCheckedValue = event.target.checked

    try {
      if (item.id) {
        addLoading()
        const id = item.id
        const estaVerificado = newCheckedValue

        // Llamamos a la API usando la función callApiWithToken
        const responseData = await callApiWithToken(
          "PUT", // Método PUT
          `${API_URL}/${id}/revisado?estaRevisado=${estaVerificado}`
        )

        // Si la solicitud es exitosa, actualizamos el estado local
        const updatedEgresados = egresados.map((egresado) =>
          egresado.id === item.id
            ? { ...egresado, estaRevisado: newCheckedValue }
            : egresado
        )
        setEgresados(updatedEgresados)

        console.log("Estado de revisión actualizado", responseData)
      }
    } catch (error) {
      console.error("Error al actualizar el estado de revisión:", error)
    } finally {
      removeLoading()
    }
  }

  // ##################################################

  useEffect(() => {
    callApiAndSetEvento()
    callApiAndSetEgresados()
  }, [])

  const actions: Action = {
    create: true,
    update: true,
    delete: true,
    export: true,
    copy: true,
  }

  const columns: Column<IEgresados>[] = [
    {
      title: "Apellido",
      field: "apellido",
      render: (rowData) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "-8px",
            }}
          >
            <Checkbox
              checked={rowData.estaRevisado || false}
              onChange={(e) => handleCheckboxChange(e, rowData)}
              color="primary"
              style={{ marginRight: 8 }}
            />
            <span style={{ flexGrow: 1, textAlign: "center" }}>
              {rowData.apellido}
            </span>
          </div>
        )
      },
    },
    { title: "Nombre", field: "nombre" },
    { title: "DNI", field: "dni" },
    { title: "Contraseña", field: "clave" },
    {
      title: "Mesa",
      field: "observaciones",
      render: (rowData: IEgresados) => {
        const maxLength = 4
        const displayText =
          rowData.observaciones.length > maxLength
            ? rowData.observaciones.substring(0, maxLength) + "..."
            : rowData.observaciones
        const tooltip = (
          <Tooltip id={`tooltip-${rowData.id}`} placement="top">
            {rowData.observaciones}{" "}
          </Tooltip>
        )
        return (
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span
              style={{
                width: "10%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {displayText}
            </span>
          </OverlayTrigger>
        )
      },
    },
    { title: "Tarjetas Pagadas", field: "tarjetasPagadas" },
    { title: "Tarjetas Pagadas Menores", field: "tarjetasPagadasMenores" },
  ]

  const customSearch = (searchText: string): Promise<IEgresados[]> => {
    return new Promise((resolve) => {
      const normalizedSearchText = normalizeString(searchText)
      const filteredData = egresados.filter((egresado) => {
        if (!egresado.nombre || !egresado.apellido || !egresado.dni)
          return false
        return [egresado.nombre, egresado.apellido, egresado.dni].some(
          (field) =>
            normalizeString(field.toString()).includes(normalizedSearchText)
        )
      })
      resolve(filteredData)
    })
  }

  const normalizeString = (str: string): string => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  }

  const handleEgresadoAdd = async (egresado: IEgresados) => {
    addLoading()
    const token = await getAccessTokenSilently()
    const newEgresado = await handleRequest("POST", API_URL, egresado, token)
    if (newEgresado) {
      setEgresados([...egresados, newEgresado])
      callApiAndSetEvento()
      callApiAndSetEgresados()
    }
    removeLoading()
  }

  const handleImportExcel = async (listaEgresados: IEgresados[]) => {
    addLoading()
    try {
      const token = await getAccessTokenSilently()
      const newEgresado = await handleRequest(
        "POST",
        `${API_URL}/saveAll`,
        listaEgresados,
        token
      )
      if (newEgresado) {
        setEgresados([...egresados, newEgresado])
        callApiAndSetEgresados()
        callApiAndSetEvento()
      }
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  const handleEgresadoEdit = async (egresado: IEgresados) => {
    addLoading()
    try {
      const token = await getAccessTokenSilently()
      const updatedEgresado = await handleRequest(
        "PUT",
        `${API_URL}/${egresado.id}`,
        egresado,
        token
      )
      if (updatedEgresado) {
        const newData = egresados.map((item) =>
          item.id === egresado.id ? updatedEgresado : item
        )
        setEgresados(newData)
        callApiAndSetEvento()
        callApiAndSetEgresados()
      }
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  const showDeleteConfirmation = (item: IEgresados) => {
    setSelectedToDelete(item)
    setDeleteConfirmation(true)
  }

  const handleDeleteConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedToDelete) {
      const egresadoId: number = selectedToDelete.id
      try {
        addLoading()
        const token = await getAccessTokenSilently()
        await handleRequest("DELETE", `${API_URL}/${egresadoId}`, null, token)
        setEgresados(egresados.filter((item) => item.id !== egresadoId))
        callApiAndSetEvento()
      } catch (error) {
        console.error(error)
      } finally {
        removeLoading()
      }
    }
    setSelectedToDelete(null)
    setDeleteConfirmation(false)
  }

  const handleFinalDeleteAllEgresados = async () => {
    setShowSecondDeleteConfirmation(false)
    addLoading()
    try {
      const token = await getAccessTokenSilently()
      await handleRequest(
        "DELETE",
        `${API_URL}/deleteAll/${idEvento}`,
        null,
        token
      )
      setEgresados([])
      setTarjetasPagadas(0)
      setTarjetasPagadasMenores(0)
    } catch (error) {
      console.error(error)
    } finally {
      removeLoading()
    }
  }

  const callApiAndSetEgresadoPorId = async (egresadoId: number) => {
    addLoading()
    try {
      const responseData = await callApiWithToken(
        "GET",
        `${API_URL}/${egresadoId}`
      )
      setSnackbarOpenError(false)
      setSelectedEditUsuario(responseData)
      setEditModalShow(true)
    } catch (error) {
      if (error instanceof Error) {
        setSnackbarMessageError(
          "Error al buscar egresado para editar: " + error.message
        )
        setSnackbarOpenError(true)
      }
      console.error("Error al obtener el egresado:", error)
    } finally {
      removeLoading()
    }
  }

  const handleAddModalOpen = () => setAddModalShow(true)
  const handleAddModalClose = () => setAddModalShow(false)

  const handleImportExcelOpen = () => setImportExcelShow(true)
  const handleImportExcelClose = () => setImportExcelShow(false)

  const handleEditModalOpen = async (item: IEgresados) => {
    await callApiAndSetEgresadoPorId(item.id)
  }

  const handleEditModalClose = () => {
    setEditModalShow(false)
    setSelectedEditUsuario(null)
  }

  const handleShowDeleteAllConfirmation = () => {
    setShowDeleteAllConfirmation(true)
  }

  const handleCloseDeleteAllConfirmation = () => {
    setShowDeleteAllConfirmation(false)
  }

  const handleDeleteAllConfirmation = (confirmed: boolean) => {
    if (confirmed) {
      setShowDeleteAllConfirmation(false) // Cierra la primera confirmación
      setShowSecondDeleteConfirmation(true) // Muestra la segunda confirmación
    } else {
      setShowDeleteAllConfirmation(false) // Cierra la primera confirmación
    }
  }

  //exportar a excel
  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet("Egresados")

    // Definir los encabezados
    const headers = [
      { header: "NOMBRE_DEL_EGRESADO", key: "nombre", width: 30 },
      { header: "TARJETAS_PAGADAS", key: "tarjetasPagadas", width: 20 },
      { header: "TARJETAS_INGRESADAS", key: "tarjetasIngresadas", width: 25 },
      {
        header: "TARJETAS_POR_INGRESAR",
        key: "tarjetasPorIngresar",
        width: 25,
      },
      {
        header: "TARJETAS_PAGADAS_MENORES",
        key: "tarjetasPagadasMenores",
        width: 30,
      },
      {
        header: "TARJETAS_INGRESADAS_MENORES",
        key: "tarjetasIngresadasMenores",
        width: 35,
      },
      {
        header: "TARJETAS_POR_INGRESAR_MENORES",
        key: "tarjetasPorIngresarMenores",
        width: 35,
      },
      { header: "TARJETAS_TOTALES", key: "tarjetasTotales", width: 18 },
      { header: "DNI", key: "dni", width: 15 },
      { header: "CLAVE", key: "clave", width: 30 },
      { header: "TELÉFONO", key: "telefono", width: 20 },
      { header: "EMAIL", key: "email", width: 40 },
      { header: "MENU_OPCIONAL", key: "menuOpcional", width: 40 },
      { header: "OBSERVACIONES", key: "observaciones", width: 40 },
    ]

    // Añadir los encabezados al worksheet
    worksheet.columns = headers

    // Establecer estilo para los encabezados
    worksheet.getRow(1).font = { bold: true }

    // Centrar el texto de todas las columnas excepto la primera
    worksheet.getColumn(2).alignment = { horizontal: "center" }
    worksheet.getColumn(3).alignment = { horizontal: "center" }
    worksheet.getColumn(4).alignment = { horizontal: "center" }
    worksheet.getColumn(5).alignment = { horizontal: "center" }
    worksheet.getColumn(6).alignment = { horizontal: "center" }
    worksheet.getColumn(7).alignment = { horizontal: "center" }
    worksheet.getColumn(8).alignment = { horizontal: "center" }
    worksheet.getColumn(9).alignment = { horizontal: "center" }
    worksheet.getColumn(10).alignment = { horizontal: "center" }
    worksheet.getColumn(11).alignment = { horizontal: "center" }
    worksheet.getColumn(12).alignment = { horizontal: "center" }
    worksheet.getColumn(13).alignment = { horizontal: "center" }
    worksheet.getColumn(14).alignment = { horizontal: "center" }

    // Añadir los datos
    const filteredData = egresados.map(
      ({
        apellido,
        nombre,
        dni,
        tarjetasPagadas,
        tarjetasIngresadas,
        tarjetasPorIngresar,
        tarjetasPagadasMenores,
        tarjetasIngresadasMenores,
        tarjetasPorIngresarMenores,
        clave,
        telefono,
        email,
        menuOpcional,
        observaciones,
      }) => ({
        nombre: `${apellido}, ${nombre}`,
        tarjetasPagadas,
        tarjetasIngresadas,
        tarjetasPorIngresar,
        tarjetasPagadasMenores,
        tarjetasIngresadasMenores,
        tarjetasPorIngresarMenores,
        tarjetasTotales: tarjetasPagadas + tarjetasPagadasMenores,
        dni,
        clave,
        telefono,
        email,
        menuOpcional,
        observaciones,
      })
    )

    // Añadir datos al worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(data)
    })

    // Generar y guardar el archivo
    const fileName = `${tituloEvento}.xlsx`
    const buffer = await workbook.xlsx.writeBuffer()

    // Crear un blob y descargar el archivo
    const blob = new Blob([buffer], { type: "application/octet-stream" })
    const link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
  }

  const handleCopy = (egresado: IEgresados) => {
    copyDataEgresado(egresado, setCopyMessage).catch((error) => {
      console.error("Error al copiar:", error)
    })
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return
    }
    setSnackbarOpenError(false)
  }

  return (
    <div>
      <Container fluid style={{ marginTop: "6rem" }}>
        {idEvento && (
          <div>
            <Row className="mb-1 align-items-center">
              <Col xs={4} className="text-start mb-2">
                <Link to="/admin" className="dropdown-item">
                  <Button variant="primary">
                    <i className="bi bi-arrow-left me-2"></i>
                    Volver a eventos
                  </Button>
                </Link>
              </Col>
              <Col xs={12}>
                <h1>{tituloEvento}</h1>
              </Col>
            </Row>
            <Row xs={12} className="mb-2">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "40px", // Espacio entre los elementos
                }}
              >
                <h2 style={{ margin: 0 }}>Mayores: {tarjetasPagadas}</h2>
                <h2 style={{ margin: 0 }}>Menores: {tarjetasPagadasMenores}</h2>
                <h2 style={{ margin: 0 }}>
                  <strong>
                    Total: {tarjetasPagadas + tarjetasPagadasMenores}
                  </strong>
                </h2>
              </Col>
            </Row>
          </div>
        )}

        {skeletonLoading ? (
          <SkeletonGrid />
        ) : (
          <>
            <Snackbar
              open={snackbarOpenError}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000} // 3 segundos
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {snackbarMessageError}
              </Alert>
            </Snackbar>

            <Row className="mt-3">
              <Col sm={12} xs={8}>
                {tieneEvento && (
                  <Button
                    variant="danger"
                    className="float-end mb-2"
                    onClick={handleShowDeleteAllConfirmation}
                  >
                    Borrar Todos los Egresados
                  </Button>
                )}
                <Box
                  sx={{
                    width: {
                      xs: "24rem", // Estilo para móviles
                      md: "100%", // Estilo para pantallas medianas y más grandes
                    },
                  }}
                >
                  <GenericTable
                    data={egresados}
                    columns={columns}
                    actions={actions}
                    onAdd={handleAddModalOpen}
                    onImport={handleImportExcelOpen}
                    onExport={handleExport}
                    onUpdate={handleEditModalOpen}
                    onDelete={showDeleteConfirmation}
                    customSearch={customSearch}
                    onCopy={handleCopy}
                    snackbarOpen={!!copyMessage}
                    snackbarMessage={copyMessage || ""}
                    tieneEvento={tieneEvento}
                    isEgresado
                  />
                </Box>

                {deleteConfirmation && (
                  <Modal
                    show={deleteConfirmation}
                    onHide={() => setDeleteConfirmation(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmar eliminación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      ¿Estás seguro de que deseas eliminar este egresado de la
                      lista?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => handleDeleteConfirmation(false)}
                      >
                        No
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteConfirmation(true)}
                      >
                        Sí
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}

                <AddEgresadoModal
                  show={addModalShow}
                  handleClose={handleAddModalClose}
                  handleEgresadoAdd={handleEgresadoAdd}
                  idEvento={idEvento}
                />

                <ImportExcelModal
                  show={importExcelShow}
                  handleClose={handleImportExcelClose}
                  handleImport={handleImportExcel}
                  idEvento={idEvento}
                />

                <EditEgresadoModal
                  show={editModalShow}
                  handleClose={handleEditModalClose}
                  handleEgresadoEdit={handleEgresadoEdit}
                  selectedEgresado={selectedEditUsuario}
                  idEvento={idEvento}
                />
              </Col>
            </Row>
            <Modal
              show={showDeleteAllConfirmation}
              onHide={handleCloseDeleteAllConfirmation}
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirmar eliminación</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Estás seguro de que deseas eliminar todos los egresados?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseDeleteAllConfirmation}
                >
                  No
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteAllConfirmation(true)}
                >
                  Sí
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showSecondDeleteConfirmation}
              onHide={() => setShowSecondDeleteConfirmation(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirmar eliminación final</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Estás seguro de que deseas eliminar{" "}
                <strong>definitivamente</strong> todos los egresados de este
                evento?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowSecondDeleteConfirmation(false)}
                >
                  No
                </Button>
                <Button
                  variant="danger"
                  onClick={handleFinalDeleteAllEgresados}
                >
                  Sí, eliminar
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </div>
  )
}
export default Egresados
