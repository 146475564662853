import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  useTheme,
} from "@mui/material"
import GeneradorQRparaModal from "./GeneradorQRparaModal/GeneradorQRparaModal"

const ModalGeneradorQR = ({
  open,
  onClose,
  dni,
  clave,
}: {
  open: boolean
  onClose: () => void
  dni: number
  clave: string
}) => {
  const theme = useTheme()

  // Establecemos los breakpoints para la posición y tamaño
  const isMobile = theme.breakpoints.down("sm")

  return (
    <div>
      {/* Modal */}
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            position: "absolute",
            top: isMobile ? "55vh" : "50%", // Centrado vertical para móviles, 50% para pantallas grandes
            left: isMobile ? "25%" : "50%", // 25% de margen izquierdo para móviles, centrado para PC
            transform: isMobile ? "translate(-50%, -50%)" : "translateX(-50%)", // Centrado en el eje Y para móviles, solo en X para PC
            width: isMobile ? "35%" : "50%", // 90% de ancho en móvil, 50% en PC
            minWidth: "300px", // Define un ancho mínimo
            maxWidth: "380px", // Define un ancho máximo
            maxHeight: "100vh", // Limita la altura máxima en móviles
            overflowY: "auto", // Permite desplazamiento si el contenido excede la altura
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          {/* Componente GeneradorQR */}
          <GeneradorQRparaModal dni={dni} clave={clave} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="error">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ModalGeneradorQR
