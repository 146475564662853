import { useEffect, useState } from "react"
import { Button, Col, Container, Row, Modal } from "react-bootstrap"
import GenericTable from "../../GenericTable/GenericTable"
import { useAuth0 } from "@auth0/auth0-react"
import { IEgresados } from "../../../interfaces/IEgresados"
import { Action, Column } from "../../../interfaces/CamposTablaGenerica"
import EditEgresadoModal from "../EditEgresadoModal/EditEgresadoModal"
import Spinner from "../../Spinner/Spinner"
import { copyDataEgresado } from "../../../utils/claveAndEncryptUtils"
import { Box } from "@mui/material"
import { formatDateGuiones } from "../../../utils/dateUtils"
import { useSpinner } from "../../../contexts/SpinnerProvider"
import ModalGeneradorQR from "./ModalGeneradorQR"

const BusquedaEgresados = () => {
  const [egresados, setEgresados] = useState<IEgresados[]>([])
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [dni, setDni] = useState("")
  const [dniQR, setDniQR] = useState(0)
  const [claveQR, setClaveQR] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const API_URL = process.env.REACT_APP_API_SERVER_URL_EGRE || ""
  const isMobile = window.innerWidth < 768
  const { addLoading, removeLoading } = useSpinner()

  // Estado para manejar la edición
  const [editModalShow, setEditModalShow] = useState(false)
  const [selectedEgresado, setSelectedEgresado] = useState<IEgresados | null>(
    null
  )

  // Estado para manejar la confirmación de eliminación
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedToDelete, setSelectedToDelete] = useState<IEgresados | null>(
    null
  )
  const [copyMessage, setCopyMessage] = useState<string>("")

  // Estado para manejar el modal GeneradorQR
  const [openQRModal, setOpenQRModal] = useState(false)

  // Funciones para abrir y cerrar el modal QR
  const handleOpenQRModal = () => {
    setOpenQRModal(true)
  }

  const handleCloseQRModal = () => {
    setOpenQRModal(false)
  }

  const handleButtonVerQR = (egresado: IEgresados) => {
    // Abrir el modal GeneradorQR cuando se haga clic en "Ver QR"
    setDniQR(egresado.dni)
    setClaveQR(egresado.clave)
    handleOpenQRModal()
  }

  const actions: Action = {
    update: true,
    delete: true,
    copy: true,
    viewQR: true,
  }

  const columns: Column<IEgresados>[] = [
    { title: "DNI", field: "dni" },
    { title: "Apellido", field: "apellido" },
    { title: "Nombre", field: "nombre" },
    {
      title: "Mesa",
      field: "observaciones",
      render: (rowData: IEgresados) => (
        <span style={{ whiteSpace: "nowrap" }}>{rowData.observaciones}</span>
      ),
    },
    {
      title: "Nombre del Evento",
      field: "evento",
      render: (rowData: IEgresados) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {rowData.evento
            ? `${rowData.evento.nombre} / ${formatDateGuiones(
                rowData.evento.fecha
              )}`
            : "Sin evento"}
        </span>
      ),
    },
  ]

  const isAnyFilterSelected = () => {
    return nombre.trim() !== "" || apellido.trim() !== "" || dni.trim() !== ""
  }

  const handleSearch = async () => {
    setLoading(true)
    setError(null)
    try {
      const token = await getAccessTokenSilently()
      const response = await fetch(
        `${API_URL}/search?nombre=${nombre}&apellido=${apellido}&dni=${dni}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        const responseData = await response.json()
        setEgresados(responseData)
      } else {
        throw new Error("Error al realizar la búsqueda")
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError("Error desconocido")
      }
    } finally {
      setLoading(false)
    }
  }

  const callApiWithToken = async (
    method: string,
    endpoint: string,
    body?: object
  ) => {
    const token = await getAccessTokenSilently()

    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          ...(method !== "GET" && { "Content-Type": "application/json" }),
        },
        ...(method !== "GET" && { body: JSON.stringify(body) }),
      })

      if (response.ok) {
        return await response.json()
      } else {
        throw new Error("Error al realizar la solicitud")
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const callApiAndSetEgresadoPorId = async (egresadoId: number) => {
    addLoading()
    try {
      const responseData = await callApiWithToken(
        "GET",
        `${API_URL}/${egresadoId}`
      )
      setError("")
      setSelectedEgresado(responseData)
      setEditModalShow(true)
    } catch (e) {
      if (e instanceof Error) {
        setError("Error al buscar egresado para editar: " + e.message)
      } else {
        setError("Error desconocido")
      }
    } finally {
      removeLoading()
    }
  }

  const handleEditModalOpen = async (egresado: IEgresados) => {
    await callApiAndSetEgresadoPorId(egresado.id)
  }

  const handleEditModalClose = () => {
    setEditModalShow(false)
    setSelectedEgresado(null)
  }

  const showDeleteConfirmation = (item: IEgresados) => {
    setSelectedToDelete(item)
    setDeleteConfirmation(true)
  }

  const handleDeleteConfirmation = async (confirmed: boolean) => {
    if (confirmed && selectedToDelete) {
      const token = await getAccessTokenSilently()
      await fetch(`${API_URL}/${selectedToDelete.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setEgresados(egresados.filter((item) => item.id !== selectedToDelete.id))
    }
    setSelectedToDelete(null)
    setDeleteConfirmation(false)
  }

  const handleEgresadoEdit = async (updatedEgresado: IEgresados) => {
    const token = await getAccessTokenSilently()
    await fetch(`${API_URL}/${updatedEgresado.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedEgresado),
    })
  }

  useEffect(() => {
    //limpiar resultados si no hay parametros de busqueda
    if (nombre.trim() == "" && apellido.trim() == "" && dni.trim() == "") {
      setEgresados([])
    }
  }, [nombre, apellido, dni])

  const handleCopy = (egresado: IEgresados) => {
    copyDataEgresado(egresado, setCopyMessage).catch((error) => {
      console.error("Error al copiar:", error)
    })
  }

  return (
    <Container fluid>
      <Box style={{ width: isMobile ? "80%" : "100%" }}>
        <h1 className="display-3">Buscar Egresados</h1>
      </Box>
      <Row className="mb-4">
        <Col sm={4}>
          <input
            type="text"
            placeholder="Apellido"
            value={apellido}
            onChange={(e) => setApellido(e.target.value)}
            className="form-control"
            style={{ width: isMobile ? "80%" : "100%", marginBottom: "10px" }}
          />
        </Col>
        <Col sm={4}>
          <input
            type="text"
            placeholder="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            className="form-control"
            style={{ width: isMobile ? "80%" : "100%", marginBottom: "10px" }}
          />
        </Col>
        <Col sm={4}>
          <input
            type="text"
            placeholder="DNI"
            value={dni}
            onChange={(e) => setDni(e.target.value)}
            className="form-control"
            style={{ width: isMobile ? "80%" : "100%" }}
          />
        </Col>
      </Row>
      <Box style={{ width: isMobile ? "80%" : "100%" }}>
        <Button
          variant="primary"
          onClick={handleSearch}
          disabled={loading || !isAnyFilterSelected()}
        >
          Buscar
        </Button>
        {loading && <Spinner marginTop="2%" marginBottom="1%" />}
      </Box>

      {error && <div className="text-danger">{error}</div>}
      <Box style={{ width: isMobile ? "80%" : "100%" }}>
        <GenericTable
          data={egresados}
          columns={columns}
          actions={actions}
          onUpdate={handleEditModalOpen}
          onDelete={showDeleteConfirmation}
          onCopy={handleCopy}
          onViewQR={handleButtonVerQR}
          snackbarOpen={!!copyMessage} // Booleano para mostrar el Snackbar
          snackbarMessage={copyMessage || ""} // Mensaje para mostrar en el Snackbar
        />
      </Box>
      {/* Modal de edición */}
      <EditEgresadoModal
        show={editModalShow}
        handleClose={handleEditModalClose}
        handleEgresadoEdit={handleEgresadoEdit}
        selectedEgresado={selectedEgresado}
        idEvento={""} // Asegúrate de pasar este prop
      />

      {/* Confirmación de eliminación */}
      <Modal
        show={deleteConfirmation}
        onHide={() => setDeleteConfirmation(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar este egresado de la lista?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleDeleteConfirmation(false)}
          >
            No
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteConfirmation(true)}
          >
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal de Generador QR */}
      <ModalGeneradorQR
        open={openQRModal}
        onClose={handleCloseQRModal}
        dni={dniQR}
        clave={claveQR}
      />
    </Container>
  )
}

export default BusquedaEgresados
