import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import "./QRMessage.css"
import { Box } from "@mui/material"

interface QRMessageProps {
  message: string
  variant: string
  tarjetasPorIngresar: string
  observaciones: string
  onClose: () => void
  onDescontarTarjetas: (cantidad: number) => void
  mostrarMesa: boolean
}

const QRMessage: React.FC<QRMessageProps> = ({
  message,
  variant,
  tarjetasPorIngresar,
  observaciones,
  onClose,
  onDescontarTarjetas,
  mostrarMesa,
}) => {
  const [tarjetasPorIngresarOriginales, setTarjetasPorIngresarOriginales] =
    useState(parseInt(tarjetasPorIngresar) || 0)
  const [tarjetasPorIngresarModal, setTarjetasPorIngresarModal] = useState(1)
  const desactivarBotones = tarjetasPorIngresarModal == 0

  const handleAddTarjetas = () => {
    if (tarjetasPorIngresarModal > 0) {
      setTarjetasPorIngresarModal((prev) => prev - 1)
    }
  }

  const handleSubstractTarjetas = () => {
    if (tarjetasPorIngresarModal < parseInt(tarjetasPorIngresar)) {
      setTarjetasPorIngresarModal((prev) => prev + 1)
    }
  }

  const handleConfirmarDescuento = () => {
    onDescontarTarjetas(tarjetasPorIngresarModal)
  }

  const handleOnclose = () => {
    setTarjetasPorIngresarModal(0)
    setTarjetasPorIngresarOriginales(0)
    onClose()
  }

  return (
    <Modal
      show={true}
      onHide={handleOnclose}
      backdrop="static"
      keyboard={false}
      centered
      style={{
        boxShadow: `0 0 10px ${variant === "danger" ? "red" : "green"}`,
      }}
    >
      <Modal.Header
        style={{
          backgroundColor: variant === "danger" ? "red" : "green",
          color: "white",
        }}
      >
        <Modal.Title
          className="display-6 text-center"
          style={{ margin: "0 auto" }}
        >
          Da Vinci Ingreso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="fs-2" style={{ whiteSpace: "pre-line" }}>
          <span className="container-message-qr"> {message}</span>
          {mostrarMesa && (
            <div className="spanMesaContainer">
              <span>
                {" "}
                {`Mesa: ${observaciones.trim() ? observaciones : "-"}`}
              </span>
            </div>
          )}
          {tarjetasPorIngresar && (
            <>
              <hr></hr>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  flex: 1,
                }}
              >
                <span className="container-message-qr">
                  {" "}
                  {"Tarjetas restantes:"}
                </span>

                <span style={{ fontWeight: "500", fontSize: "28px" }}>
                  {" " + tarjetasPorIngresarOriginales || ""}
                </span>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Box className="flex-column align-items-center">
          {!desactivarBotones && !!tarjetasPorIngresarOriginales && (
            <Button
              variant="primary"
              className="me-1 py-1 px-3"
              onClick={handleAddTarjetas}
              disabled={tarjetasPorIngresarModal == 1}
            >
              <span style={{ fontWeight: "800", fontSize: "20px" }}>-</span>
            </Button>
          )}
          {tarjetasPorIngresarModal > 0 && !!tarjetasPorIngresarOriginales && (
            <Button
              variant="success"
              className="mx-2"
              onClick={handleConfirmarDescuento}
            >
              <span
                style={{ fontSize: "18px" }}
              >{`Descontar ${tarjetasPorIngresarModal}`}</span>
            </Button>
          )}
          {!desactivarBotones && !!tarjetasPorIngresarOriginales && (
            <Button
              variant="primary"
              className="ms-1 me-3 py-1 px-3"
              disabled={tarjetasPorIngresarOriginales == 0}
              onClick={handleSubstractTarjetas}
            >
              <span style={{ fontWeight: "800", fontSize: "20px" }}>+</span>
            </Button>
          )}
        </Box>
        <Box className="flex-column align-items-center">
          <Button
            variant="danger"
            className="boton-close-modal"
            onClick={handleOnclose}
          >
            Cerrar
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}

export default QRMessage
